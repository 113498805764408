import React, { useState } from "react";
import { APIwithAuth } from "../AxiosAPI/AxiosAPI";

const Navbar = ({
  onLoginButtonClick,
  onSignupButtonClick,
  userName,
  onLogoutSuccessfully,
  token,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  // Handle logout request
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await sendToAPI();
      console.log("Response from API: ", response);
      if (response.status) {
        onLogoutSuccessfully();
      } else {
        if (response.message != null) {
          console.log(response.message);
        } else {
          console.log("Something went wrong with logout API response");
        }
      }
    } catch (error) {
      if (error.isAxiosError) {
        console.log("AxiosError:", error.message);
      } else {
        console.error("API request error:", error);
      }
    }
    onLogoutSuccessfully();
  };

  const sendToAPI = async () => {
    const apiInstance = APIwithAuth(token);
    const response = await apiInstance.get("/api/logout");
    console.log("Response logout: ", response);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error("API Error");
    }
    return response.data;
  };

  const toggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/images/logos/FastAddressFullLogo_50.png" alt="Logo" />
        </a>
        <ul className="navbar-nav mx-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/personal"
              id="navbarDropdownMenuLink_Personal"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Personal
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink_Personal"
            >
              <li>
                <a className="dropdown-item" href="/personal">
                  Resume
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/personal">
                  Free?
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/personal">
                  Sign up
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/business"
              id="navbarDropdownMenuLink_Business"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Business
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink_Business"
            >
              <li>
                <a className="dropdown-item" href="/business">
                  Resume
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/business">
                  API
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/business">
                  Sign up
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/company"
              id="navbarDropdownMenuLink_Company"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Company
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink_Company"
            >
              <li>
                <a className="dropdown-item" href="/company">
                  About us
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/company">
                  Sustainability
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/company">
                  Careers
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/company">
                  Leadership
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="d-flex ms-auto signLogButtons">
          {!userName && (
            <>
              <button
                className="btn btn-secondary ms-3"
                onClick={onSignupButtonClick}
              >
                Sign up
              </button>
              <button className="btn btn-dark ms-3" onClick={onLoginButtonClick}>
                Log in
              </button>
            </>
          )}
          {userName && (
            <>
              <span className="navbar-text me-3">{userName}</span>
              <button className="btn btn-dark ms-3" onClick={handleLogout}>
                Log out
              </button>
            </>
          )}
          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
            onClick={toggleOverlay}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        {/* Overlay Menu */}
        <div className={`overlay-menu ${isOverlayOpen ? "show" : ""}`}>
          <div className="overlay-content">
            <ul className="navbar-nav mx-auto d-block">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/personal"
                  id="navbarDropdownMenuLink_Personal"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Personal
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink_Personal"
                >
                  <li>
                    <a className="dropdown-item" href="/personal">
                      Resume
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/personal">
                      Free?
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/personal">
                      Sign up
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/business"
                  id="navbarDropdownMenuLink_Business"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Business
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink_Business"
                >
                  <li>
                    <a className="dropdown-item" href="/business">
                      Resume
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/business">
                      API
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/business">
                      Sign up
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/company"
                  id="navbarDropdownMenuLink_Company"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink_Company"
                >
                  <li>
                    <a className="dropdown-item" href="/company">
                      About us
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/company">
                      Sustainability
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/company">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/company">
                      Leadership
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </nav>
  );
};

export default Navbar;
