import Pica from "pica";

// Image global settings
import { IMAGE_MAX_FILE_SIZE, IMAGE_MAX_PIXEL_SIZE } from "../config";

const pica = new Pica();

export const resizeImage = async (file) => {
  const img = new Image();
  const reader = new FileReader();

  const resize = async (image, width, height) => {
    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    await pica.resize(image, canvas);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(new File([blob], file.name, { type: file.type }));
      }, file.type);
    });
  };

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = async () => {
      let width = img.width;
      let height = img.height;

      // Resize the image maintaining aspect ratio
      if (width > height) {
        if (width > IMAGE_MAX_PIXEL_SIZE) {
          height *= IMAGE_MAX_PIXEL_SIZE / width;
          width = IMAGE_MAX_PIXEL_SIZE;
        }
      } else {
        if (height > IMAGE_MAX_PIXEL_SIZE) {
          width *= IMAGE_MAX_PIXEL_SIZE / height;
          height = IMAGE_MAX_PIXEL_SIZE;
        }
      }

      let resizedFile = await resize(img, width, height);

      // Check if the resized file exceeds the IMAGE_MAX_FILE_SIZE
      while (resizedFile.size > IMAGE_MAX_FILE_SIZE) {
        width *= 0.9;
        height *= 0.9;
        resizedFile = await resize(img, width, height);
      }

      resolve(resizedFile);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
