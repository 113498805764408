// React
import React, { useEffect, useState, useCallback } from "react";

// Animations
import { SyncLoader } from "react-spinners";

// API
import { APIwithAuth } from "../../AxiosAPI/AxiosAPI";

// AddAddress
import AddAddress from "./AddAddress";

// EditAddress
import EditAddress from "./EditAddress";

// Dynamic button list
import AddressButtonList from "../../Components/AddressButtonList";

const UserMainPage = ({
  userID,
  userName,
  userPhone,
  userEmail,
  token,
  onUnAuthorized,
}) => {
  // *****************************
  // User current addresses
  const [userAddresses, setUserAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [userUsedHashes, setUserUsedHashes] = useState({});

  // Memoize the onUnAuthorized function
  const onUnAuthorizedCallback = useCallback(onUnAuthorized, [onUnAuthorized]);

  // Fetch user addresses, function used on page load and when user adds or deletes an address
  const fetchUserAddresses = useCallback(async () => {
    //console.log("Fetching UserAddresses: ");
    const apiInstance = APIwithAuth(token);
    try {
      const response = await apiInstance.get("/api/user/addresses");
      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }
      const addressesData = response.data.addresses || [];
      setUserAddresses(addressesData);
      const uniqueHashcodes = [
        ...new Set(addressesData.map((address) => address.address_hashcode)),
      ];
      setUserUsedHashes(uniqueHashcodes);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        console.error("Error fetching user addresses:", error);
        throw error;
      }
    }
  }, [token, onUnAuthorizedCallback]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading to true to indicate that data is being fetched
        setLoading(true);
        await fetchUserAddresses();
      } catch (error) {
        console.error("Error fetching user addresses:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchUserAddresses]);

  // **************************
  // Handle add address
  const toggleAddAddress = () => {
    setShowAddAddress(!showAddAddress);
  };

  const handleCloseAddAddress = async () => {
    setShowAddAddress(false);
    try {
      setLoading(true);
      await fetchUserAddresses(); // Refresh addresses list after closing AddAddress
    } catch (error) {
      console.error("Error refreshing addresses:", error);
    } finally {
      setLoading(false);
    }
  };

  // **************************
  // Handle delete address

  // Delete address API call
  const deleteAddressAPI = async (addressID) => {
    const apiInstance = APIwithAuth(token);
    console.log("SoftDeleting address with ID: ", addressID);
    try {
      const response = await apiInstance.post(
        `/api/user/addresses/${addressID}/softdelete`
      );

      console.log(
        "(deleteAddressAPI)Response softdelete address: ",
        response.data
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        // If it's a different error, rethrow it
        console.error(
          "Error softdeleting user address - not handled error:",
          error
        );
        throw error;
      }
    }
  };

  const handleDeleteAddress = async (addressID) => {
    console.log("handleDeleteAddress: ", addressID);

    try {
      const result = await deleteAddressAPI(addressID);
      console.log("handleDeleteAddress: ", result);
      // Refresh user addresses after deletion
      setUserAddresses(
        userAddresses.filter((address) => address.id !== addressID)
      );
    } catch (error) {
      console.error("handleDeleteAddress error:", error);
    }
  };


  // **************************
  // Handle edit address click
  const handleEditAddressClick = (address) => {
    console.log("handleEditAddressClick: ", address);
    setEditAddress(address);
  }


  const handleCloseEditAddress = async () => {
    setEditAddress(false);
    try {
      setLoading(true);
      await fetchUserAddresses(); // Refresh addresses list after closing AddAddress
    } catch (error) {
      console.error("Error refreshing addresses:", error);
    } finally {
      setLoading(false);
    }
  };

  // **************************
  // Render
  //
  //
  //
  //
  //
  //

  // Render component based on the state
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <SyncLoader
          color={"#4463FF"}
          loading={loading}
          size={20}
          css={{ marginTop: "90px" }}
        />
      </div>
    );
  }

  // Show address list or new address
  return (
    <div
      style={{
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        marginTop: "90px",
      }}
    >
      {!showAddAddress && !editAddress &&(
        <div>
          <h1 style={{ color: "darkslateblue" }}>My addresses</h1>
          <h2>+{userPhone}</h2>
          <div style={{ marginTop: "20px" }}></div>
          <AddressButtonList
            addresses={userAddresses}
            userPhone={userPhone}
            onAddAddress={toggleAddAddress}
            onDeleteAddress={handleDeleteAddress}
            onEditAddress={handleEditAddressClick}
          />
        </div>
      )}
      {showAddAddress && !editAddress && (
        <AddAddress
          userID={userID}
          userName={userName}
          userPhone={userPhone}
          userEmail={userEmail}
          userUsedHashes={userUsedHashes}
          onClose={handleCloseAddAddress}
          token={token}
          onUnAuthorized={onUnAuthorizedCallback}
        />
      )}
      {editAddress && !showAddAddress && (
        <EditAddress
          address={editAddress}
          userPhone={userPhone}
          userEmail={userEmail}
          userUsedHashes={userUsedHashes}
          onClose={handleCloseEditAddress}
          token={token}
          onUnAuthorized={onUnAuthorizedCallback}
        />
      )}
    </div>
  );
};

export default UserMainPage;
