import React, { useState, useEffect } from 'react';

const InlineNotification = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: `5px`,
        left: `0px`,
        padding: '10px',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        borderRadius: '4px',
        display: visible ? 'block' : 'none',
      }}
    >
      {message}
    </div>
  );
};

export default InlineNotification;