// AddAddress.js
import React, { useState, useCallback } from "react";

// Phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";

// Animations
import { css } from "@emotion/react";
import { SyncLoader } from "react-spinners";

// Email validation
import * as EmailValidator from "email-validator";

// Country and region
import CountryRegionSelector from "../../Components/CountryRegionSelector/CountryRegionSelector.js";

// To fetch the coordinates based on the city location
import axios from "axios";

// OpenStreetMap
import OpenStreetMapComponent from "../../Components/OpenStreetViewComponent.js";

// Imageupload
import ImageUpload from "../../Components/ImageUpload.js";

// Image resize
import { resizeImage } from "../../utils/resizeImage.js";
import { IMAGE_MAX_FILE_SIZE, IMAGE_MAX_PIXEL_SIZE } from "../../config";

// API
import { APIwithAuth } from "../../AxiosAPI/AxiosAPI";

const AddAddress = ({
  userID,
  userName,
  userPhone,
  userEmail,
  userUsedHashes,
  onClose,
  token,
  onUnAuthorized,
}) => {
  // Menu 1
  const [showMenu1SecondQuestion, setShowMenu1SecondQuestion] = useState(false);
  const [companyLocation, setCompanyLocation] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [fiscalID, setFiscalID] = useState("");
  const [contactName, setContactName] = useState(userName);
  const [phone, setPhone] = useState(userPhone);
  const [email, setEmail] = useState(userEmail);

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(false);
  const [isFiscalIDValid, setIsFiscalIDValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true); // Already filled
  const [isPhoneValid, setIsPhoneValid] = useState(true); // Already filled
  const [isEmailValid, setIsEmailValid] = useState(true); // Already filled
  const [isCompanyValid, setIsCompanyValid] = useState(false); // Company valid means name and fiscal number
  const [isMenu1Valid, setIsMenu1Valid] = useState(false);

  // Menu 2
  const [designation, setDesignation] = useState("");
  const [isPublic, setIsPublic] = useState(null);
  const [showMenu2SecondQuestion, setShowMenu2SecondQuestion] = useState(false);
  const [hashCode, setHashCode] = useState("");
  const [hashErrorMessage, setHashErrorMessage] = useState("1 to 5 characters");

  const [isDesignationValid, setIsDesignationValid] = useState(false);
  const [isHashCodeValid, setIsHashCodeValid] = useState(false);
  const [isMenu2Valid, setIsMenu2Valid] = useState(false);

  // Menu 3
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [addressLines, setAddressLines] = useState("");

  const [isCityValid, setIsCityValid] = useState(null);
  const [isAddressLinesValid, setIsAddressLinesValid] = useState(null);
  const [isMenu3Valid, setIsMenu3Valid] = useState(false);

  // Menu 4
  const [coordinates, setCoordinates] = useState({
    lat: 40.73061,
    lng: -73.935242,
  });
  const [notes, setNotes] = useState("");

  // Menu 5
  const [selectedFiles, setSelectedFiles] = useState([]);

  // General
  const [menuNumber, setMenuNumber] = useState(1);
  const usedHashes = useState(userUsedHashes);
  // Memoize the onUnAuthorized function
  const onUnAuthorizedCallback = useCallback(onUnAuthorized, [onUnAuthorized]);
  const [loading, setLoading] = useState(false);
  const animationCSS = css`
    display: block;
    margin: 0 auto;
  `;

  // ********************************************************************
  // Menu 1

  const handleResponseToCompanyQuestion = (response) => {
    setCompanyLocation(response === true);
    setShowMenu1SecondQuestion(true); // Ask company details or contact details

    // Update menu 1 validity
    if (
      response === true &&
      isCompanyNameValid &&
      isFiscalIDValid &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsMenu1Valid(true);
    } else if (
      response === false &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsMenu1Valid(true);
    } else setIsMenu1Valid(false);
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    const isValid = value.length >= 3 && value.length <= 100;
    setIsCompanyNameValid(isValid); // Validate input length

    // Update company information validity
    if (
      companyLocation &&
      isValid &&
      isFiscalIDValid &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsCompanyValid(isValid);
    } else setIsCompanyValid(false);

    // Update menu 1 validity
  };

  const handleFiscalIDChange = (e) => {
    const value = e.target.value;
    setFiscalID(value);
    const isValid = value.length >= 3 && value.length <= 50;
    setIsFiscalIDValid(isValid); // Validate input length

    // Update company information validity
    if (companyLocation && isValid && isCompanyNameValid) {
      setIsCompanyValid(isValid);
    } else setIsCompanyValid(false);

    // Update menu 1 validity
    if (
      companyLocation &&
      isCompanyNameValid &&
      isValid &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setContactName(value);
    const isValid = value.length >= 3 && value.length <= 100;
    setIsNameValid(isValid); // Validate input length

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isValid &&
        isPhoneValid &&
        isEmailValid) ||
      (!companyLocation && isValid && isPhoneValid && isEmailValid)
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);

    const phoneNumber = parsePhoneNumber(formattedValue);

    const isValid = phoneNumber && phoneNumber.isValid();
    setIsPhoneValid(isValid);

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isNameValid &&
        isValid &&
        isEmailValid) ||
      (!companyLocation && isNameValid && isValid && isEmailValid)
    ) {
      setIsMenu1Valid(true);
    } else setIsMenu1Valid(false);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const isValid = EmailValidator.validate(value);
    setIsEmailValid(isValid);

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isNameValid &&
        isPhoneValid &&
        isValid) ||
      (!companyLocation && isNameValid && isPhoneValid && isValid)
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handleMenu1PressNext = () => {
    // Logic to determine next question or action based on current state
    if (companyLocation) {
      console.log("Company Name:", companyName);
      console.log("FiscalID:", fiscalID);
      // Implement logic to proceed to next step or submit form
    } else {
      console.log("Person Name:", contactName);
      // Implement logic to proceed to next step or submit form
    }
    setMenuNumber(2);
  };

  // ********************************************************************
  // Menu 2

  const handleDesignationChange = (e) => {
    const value = e.target.value;
    setDesignation(value);
    const isValid = value.length >= 4 && value.length <= 100;
    setIsDesignationValid(isValid); // Validate input length

    if (isValid && (isPublic || (!isPublic && isHashCodeValid))) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleHashCodeChange = (e) => {
    const value = e.target.value;
    setHashCode(value);

    if (usedHashes[0].includes(value))
      setHashErrorMessage("Hashcode already in use");
    else setHashErrorMessage("1 to 5 characters");

    const isValid =
      value.length >= 1 && value.length <= 50 && !usedHashes[0].includes(value);
    setIsHashCodeValid(isValid); // Validate input length

    if (isDesignationValid && (isPublic || (!isPublic && isValid))) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleResponseToIsPublicQuestion = (response) => {
    setIsPublic(response === true);
    setShowMenu2SecondQuestion(true);

    // Update menu 2 validity
    if (response === true && isDesignationValid) {
      setIsMenu2Valid(true);
    } else if (response === false && isDesignationValid && isHashCodeValid) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleMenu2PressNext = () => {
    // console.log("Designation:", designation);
    // console.log("hashcode:", hashCode);

    // Prepare the default country of the menu 3 on the phone number selected on the menu 2
    const phoneNumber = parsePhoneNumber("+" + phone);
    const isValid = phoneNumber && phoneNumber.isValid();
    if (isValid) {
      setCountry(phoneNumber.country);
      //console.log ("Phone number valid: ", phone, " resulting in country: ", phoneNumber.country);
    } else console.log("Phone number is not valid: ", phone);

    setMenuNumber(3);
  };

  const handleMenu2PressBack = () => {
    // Logic to determine next question or action based on current state
    console.log("Designation:", designation);
    console.log("hashcode:", hashCode);
    setMenuNumber(1);
  };

  // ********************************************************************
  // Menu 3

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handleRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);
    const isValid = value.length >= 2 && value.length <= 100;
    setIsCityValid(isValid); // Validate input length

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    setPostalCode(value);
  };

  const handleAddressLinesChange = (e) => {
    const value = e.target.value;
    setAddressLines(value);
    const isValid = value.length >= 5 && value.length <= 100;
    setIsAddressLinesValid(isValid); // Validate input length

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const geocode = async (city, country) => {
    const query = `${city}, ${country}`;
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      query
    )}`;
    try {
      const response = await axios.get(url);
      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        return { lat: parseFloat(lat), lon: parseFloat(lon) };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error;
    }
  };

  const handleMenu3PressNext = async () => {
    try {
      const result = await geocode(city, country);
      setCoordinates(result);
      //console.log("Geocoding ok, returning coordinates:", result);
    } catch (error) {
      console.error("Geocoding failed:", error);
    }

    setMenuNumber(4);
  };

  const handleMenu3PressBack = () => {
    setMenuNumber(2);
  };

  // ********************************************************************
  // Menu 4

  const updateCoordinates = (newCoordinates) => {
    setCoordinates(newCoordinates);
    //console.log("Coordinates on add address: ", newCoordinates);
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotes(value);
  };

  const handleMenu4PressNext = () => {
    setMenuNumber(5);
  };

  const handleMenu4PressBack = () => {
    setMenuNumber(3);
  };

  // ********************************************************************
  // Menu 5

  // Submit button to create the address. This is where all the data is sent to the API
  const handleMenu5Create = () => {
    // The address is composed of the data from the 5 menus and uses 3 tables on the database
    // 1 - localization
    // 2 - address
    // 3 - images

    // 1 - Localization creation, call the async function
    createLocationData()
      .then((result) => {
        if (result !== -1) {
          console.log(
            "createLocationData completed successfully, id: ",
            result
          );
          // Continue with other operations...

          // Address creation
          createAddressData(result);
        } else {
          console.error("createLocationData failed.");
        }
      })
      .catch((error) => {
        console.error("Error in createLocationData: ", error);
      });
  };

  const handleMenu5PressBack = () => {
    setMenuNumber(4);
  };

  const handleImagesUpdate = (images) => {
    // Handle the uploaded images
    setSelectedFiles(images);

    console.log("Uploaded images:", images);
  };

  // ********************************************************************
  // API calls
  const createLocation = async () => {
    const apiInstance = APIwithAuth(token);
    try {
      const formatedData = {
        country_id: 0,
        country_acronym: country,
        contact_name: contactName,
        phone: "+" + phone,
        email: email,
        company: companyName,
        fiscal_id: fiscalID,
        address_lines: addressLines,
        postal_code: postalCode,
        city: city,
        region: region,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        notes: notes,
      };

      //console.log("Data to be sent to create the location: ", formatedData, coordinates);

      const response = await apiInstance.post(
        "/api/user/locations",
        formatedData
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages
        ) {
          console.log(
            "error.response.data.messages: ",
            error.response.data.messages
          );
        } else {
          // Handle other errors
          console.error("Error creating the location:", error);
          // Rethrow the error to propagate it further if needed
          throw error;
        }
      }
    }
  };

  // Creates the location in the database and returns the id of the location
  // If error returns -1
  const createLocationData = async () => {
    try {
      // Set loading to true to indicate that data is being fetched
      setLoading(true);

      const createLocationResponse = await createLocation();

      console.log("Response createLocationResponse: ", createLocationResponse);

      if (
        createLocationResponse.status === 200 ||
        createLocationResponse.status === 201
      ) {
        // Return the id of the location
        return createLocationResponse.messages.resourceId;
      }
    } catch (error) {
      console.error("Error creating the location: ", error);
      return -1;
    } finally {
      // Set loading to false when the operation is complete (either success or error)
      setLoading(false);
    }
  };

  const createImagesObjects = async () => {
    try {
      const fileObjects = await Promise.all(
        selectedFiles.map(async (file) => {
          let processedFile = file;

          // Check if the file exceeds the maxFileSizeImage or dimensions
          const shouldResize = await new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
              img.src = e.target.result;
            };

            img.onload = () => {
              if (
                file.size > IMAGE_MAX_FILE_SIZE ||
                img.width > IMAGE_MAX_PIXEL_SIZE ||
                img.height > IMAGE_MAX_PIXEL_SIZE
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            };

            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
          });

          // Resize the image if it exceeds the maxFileSizeImage or dimensions
          if (shouldResize) {
            processedFile = await resizeImage(file);
          }

          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result;
              resolve({
                filename: processedFile.name,
                image: base64String,
              });
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(processedFile);
          });
        })
      );

      const base64Images = fileObjects.map((obj) => obj.image);
      const fileNames = fileObjects.map((obj) => obj.filename);

      return { base64Images, fileNames };
    } catch (error) {
      console.error("Error converting files:", error);
      throw error;
    }
  };

  // Creates the address in the database, it includes the images as the API receives the images
  // as a base64 string, under the 'image' array

  const createAddress = async (location_id) => {
    const apiInstance = APIwithAuth(token);

    const { base64Images, fileNames } = await createImagesObjects(); // Wait for the images to be converted to base64 strings

    try {
      const formatedData = {
        location_id: location_id,
        user_id: userID,
        designation: designation,
        hashcode: hashCode,
        image: base64Images,
        filename: fileNames,
      };

      console.log("Data to be sent to create the address: ", formatedData);

      const response = await apiInstance.post(
        "/api/user/addresses",
        formatedData
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }

      return response.data;
    } catch (error) {
      // Handle different types of errors
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        // console.log ("error.response: ", error.response);
        // console.log ("response.data: ", error.response.data);
        // console.log ("error.response.data.error: ", error.response.data.error);
        // console.log ("error.response.data.messages.sqlState: ", error.response.data.messages.sqlState);

        // If it's a different error, check if it's an API error with details
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages
        ) {
          const sqlState = error.response.data.messages.sqlState;

          // Check if the error is due to a duplicate entry
          if (sqlState === "45000") {
            console.error(
              "45000: Duplicate address entry set on address table trigger"
            );
          } else {
            // Handle other API errors
            console.error("API Error:", error);
          }
        } else {
          // Handle other errors
          console.error("Error creating the address:", error);
          // Rethrow the error to propagate it further if needed
          throw error;
        }
      }
    }
  };

  const createAddressData = async (location_id) => {
    try {
      // Set loading to true to indicate that data is being transmitted
      setLoading(true);

      const createAddressResponse = await createAddress(location_id);

      console.log("Response createAddressResponse: ", createAddressResponse);

      // Should this check if the address was created successfully
      onClose();
    } catch (error) {
      console.error("Error creating the address: ", error);
    } finally {
      // Set loading to false when the operation is complete (either success or error)
      setLoading(false);
    }
  };

  //
  //
  //
  //
  // ********************************************************************
  // Render
  // ********************************************************************

  return (
    <div
      name="AddAddress" // Home page container
      style={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      {menuNumber === 1 && (
        <div name="menu1" className="centralForm">
          {/* Question: The address refers to a company location */}
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>New</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>1/5</span>{" "}
          </h1>
          <div>
            <p>
              To create an accurate address you need to supply its information.
            </p>
            Don't worry questions are simple and you can always edit them later.
            We are here to make your address managment simple.
          </div>
          <div>
            <h4 style={{ marginTop: "70px", marginBottom: "20px" }}>
              The address refers to a business/company or institution?
            </h4>
          </div>

          <div className="twoHorizontalButtons">
            <button
              className={`${
                companyLocation === true ? "selectedButton" : "unSelectedButton"
              }`}
              onClick={() => handleResponseToCompanyQuestion(true)}
            >
              Yes
            </button>
            <button
              className={`${
                companyLocation === false
                  ? "selectedButton"
                  : "unSelectedButton"
              }`}
              onClick={() => handleResponseToCompanyQuestion(false)}
            >
              No
            </button>
          </div>

          {/* Depending on the answer, show appropriate input */}
          {companyLocation && showMenu1SecondQuestion && (
            <div>
              <div className="inputGroup">
                {/* Input field: What is the company name? */}
                <div className="inputLabel">
                  Company/business/institution name
                </div>
                <input
                  className="textInput"
                  type="text"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  autoComplete="false"
                />
                {!isCompanyNameValid && companyName.length > 0 && (
                  <div className="validationMessage">3 to 100 characters</div>
                )}
              </div>
              <div className="inputGroup">
                {/* Input field: Fiscal number? */}
                <div className="inputLabel">
                  Fiscal number/vat number/GST/registration number (company
                  identifier)
                </div>
                <input
                  className="textInput"
                  type="text"
                  value={fiscalID}
                  onChange={handleFiscalIDChange}
                  autoComplete="false"
                />
                {!isFiscalIDValid && fiscalID.length > 0 && (
                  <div className="validationMessage">3 to 50 characters</div>
                )}
              </div>

              {/* Company address and after the company data is filled */}
              {isCompanyValid && (
                <div>
                  <div className="inputGroup">
                    {/* Input field: What is the contact name? */}
                    <div className="inputLabel">
                      Address contact name (can be you or another person on the
                      address)
                    </div>
                    <input
                      className="textInput"
                      type="text"
                      value={contactName}
                      onChange={handleNameChange}
                      autoComplete="false"
                    />
                    {!isNameValid && contactName.length > 0 && (
                      <div className="validationMessage">
                        3 to 100 characters
                      </div>
                    )}
                  </div>

                  <div className="inputGroup">
                    {/* Input field: Contact phone number? */}
                    <div className="inputLabel">
                      Address contact phone number
                    </div>
                    <PhoneInput
                      className="addAddressPhoneInput"
                      name="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      countryCodeEditable={false}
                      inputProps={{
                        required: true,
                        autoComplete: "phone",
                      }}
                    />
                    {!isPhoneValid && phone.length > 5 && (
                      <div className="validationMessage">
                        Phone number is not valid
                      </div>
                    )}
                  </div>

                  <div className="inputGroup">
                    {/* Input field: What is the contact email? */}
                    <div className="inputLabel">Address contact email</div>
                    <input
                      className="textInput"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      autoComplete="false"
                    />
                    {!isEmailValid && email.length > 3 && (
                      <div className="validationMessage">
                        Email must be a valid
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {!companyLocation && showMenu1SecondQuestion && (
            <div>
              <div className="inputGroup">
                {/* Input field: What is the person name? */}
                <div className="inputLabel">
                  Address contact name (can be you or another person on the
                  address)
                </div>
                <input
                  className="textInput"
                  type="text"
                  value={contactName}
                  onChange={handleNameChange}
                  autoComplete="false"
                />
                {!isNameValid && contactName.length > 0 && (
                  <div className="validationMessage">3 to 100 characters</div>
                )}
              </div>

              <div className="inputGroup">
                {/* Input field: Contact phone number? */}
                <div className="inputLabel">Address contact phone number</div>
                <PhoneInput
                  className="addAddressPhoneInput"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  countryCodeEditable={false}
                  inputProps={{
                    required: true,
                    autoComplete: "phone",
                  }}
                />
                {!isPhoneValid && phone.length > 5 && (
                  <div className="validationMessage">
                    Phone number is not valid
                  </div>
                )}
              </div>

              <div className="inputGroup">
                {/* Input field: What is the contact email? */}
                <div className="inputLabel">Address contact email</div>
                <input
                  className="textInput"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="false"
                />
                {!isEmailValid && email.length > 3 && (
                  <div className="validationMessage">Email must be a valid</div>
                )}
              </div>
            </div>
          )}

          {/* Lower button for next */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              {isMenu1Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu1PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>

          {/* Lower button to cancel */}
          <div style={{ marginTop: "40px" }}>
            <div className="twoHorizontalButtons">
              <button className="cancelButton" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {menuNumber === 2 && (
        <div name="menu2" className="centralForm">
          {/* Designation */}
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>New</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>2/5</span>{" "}
          </h1>

          <div>
            <h4 style={{ marginTop: "70px", marginBottom: "20px" }}>
              What designation do you want to give to your address?{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Home, Work, Amazon, Uber{" "}
              </span>{" "}
              are good examples
            </h4>
          </div>

          <div className="inputGroup">
            {/* Input field: Address designation */}
            <div className="inputLabel">
              Designation. Keep it simple and accurate as a title should be
            </div>
            <input
              className="textInput"
              type="text"
              value={designation}
              onChange={handleDesignationChange}
              autoComplete="false"
            />
            {!isDesignationValid && designation.length > 0 && (
              <div className="validationMessage">4 to 100 characters</div>
            )}
          </div>

          {isDesignationValid && (
            <div>
              <div>
                <h4 style={{ marginTop: "70px", marginBottom: "5px" }}>
                  Do you want to make this address{" "}
                  <span style={{ fontWeight: "bold" }}>public</span>?
                </h4>
                <h6 style={{ marginTop: "0px", marginBottom: "20px" }}>
                  If set to public, anyone can see it when searching on our
                  platform just using your phone number. If non-public, to
                  obtain your address information, a'secret' is required. This
                  means that you will have control over your address visibility.
                  You can only have one public address at a time.
                </h6>
              </div>

              <div className="twoHorizontalButtonsLarge">
                <button
                  className="unSelectedButtonLarge"
                  onClick={() => handleResponseToIsPublicQuestion(true)}
                  style={{
                    backgroundColor: usedHashes[0].includes("")
                      ? "#FFC1AD"
                      : isPublic === false ? "#e3edf4" : "#78bdee",
                  }}
                  disabled={usedHashes[0].includes("")}
                >
                  {usedHashes[0].includes("")
                    ? "Yes - public (already in use)"
                    : "Yes - public"}
                </button>
                <button
                  className={`${
                    isPublic === false
                      ? "selectedButtonLarge"
                      : "unSelectedButtonLarge"
                  }`}
                  onClick={() => handleResponseToIsPublicQuestion(false)}
                >
                  No - secret will be needed
                </button>
              </div>

              {/* Depending on the answer, show appropriate input */}
              {!isPublic && showMenu2SecondQuestion && (
                <div>
                  <div className="inputGroup">
                    {/* Input field: What is the code/secret to be used? */}
                    <div className="inputLabel">
                      Secret/code to be associated with this address. Without
                      it, no one can see this address
                    </div>
                    <input
                      className="textInput"
                      type="text"
                      value={hashCode}
                      onChange={handleHashCodeChange}
                      autoComplete="false"
                    />
                    {!isHashCodeValid > 0 && (
                      <div className="validationMessage">
                        {hashErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Lower buttons to go forward, back or cancel on menu 2*/}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                className="unSelectedButton"
                onClick={handleMenu2PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>
              {isMenu2Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu2PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 3 - Address details */}
      {menuNumber === 3 && (
        <div name="menu3" className="centralForm">
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>New</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>3/5</span>{" "}
          </h1>

          <div>
            <h4 style={{ marginTop: "70px", marginBottom: "20px" }}>
              Lets provide more details to make it accurate
            </h4>
          </div>

          {/* Country and region selector */}
          <div>
            <CountryRegionSelector
              disabled={false}
              selectedCountry={country}
              setSelectedCountry={handleCountryChange}
              selectedRegion={region}
              setSelectedRegion={handleRegionChange}
            />
          </div>

          {/* Show city and postal code after region is filled */}
          {region && (
            <div>
              {/* 2 columns, for city and postal code */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="inputGroup"
                  style={{ width: "45%", marginTop: "10px" }}
                >
                  {/* Input field: city */}
                  <div className="inputLabel">City</div>
                  <input
                    className="textInput"
                    type="text"
                    value={city}
                    onChange={handleCityChange}
                    autoComplete="false"
                  />
                  {!isCityValid && city.length > 0 && (
                    <div className="validationMessage">2 to 50 characters</div>
                  )}
                </div>

                <div
                  className="inputGroup"
                  style={{ width: "45%", marginTop: "10px" }}
                >
                  {/* Input field: postal code */}
                  <div className="inputLabel">Postal code</div>
                  <input
                    className="textInput"
                    type="text"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    autoComplete="false"
                  />
                </div>
              </div>

              {/* Show address lines after city is filled */}
              {isCityValid && (
                <div className="inputGroup">
                  {/* Input field: addressLines */}
                  <div className="inputLabel">
                    Address details (street, number or your address as you know
                    it will be understood by others...)
                  </div>
                  <input
                    className="textInput"
                    type="text"
                    value={addressLines}
                    onChange={handleAddressLinesChange}
                    autoComplete="false"
                  />
                  {!isAddressLinesValid && addressLines.length > 0 && (
                    <div className="validationMessage">5 to 100 characters</div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Lower buttons to go forward, back or cancel on menu 3 */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                className="unSelectedButton"
                onClick={handleMenu3PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>
              {isMenu3Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu3PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 4 - Open street view*/}
      {menuNumber === 4 && (
        <div name="menu4" style={{ width: "min(93vw, 1200px)" }}>
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>New</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>4/5</span>{" "}
          </h1>

          <div>
            <h5 style={{ marginTop: "70px", marginBottom: "20px" }}>
              Increase the precision of your address locating a precise point on
              the map. It can be later used to navigate until your location.
            </h5>
          </div>

          <OpenStreetMapComponent
            initialCoordinates={coordinates}
            onUpdateCoordinates={updateCoordinates}
          />

          <div
            className="inputGroup"
            style={{ width: "100%", marginTop: "10px" }}
          >
            {/* Input field: notes */}
            <div className="inputLabel">
              Optionally write some notes that might help other to reach your
              address
            </div>
            <input
              className="textInput"
              type="text"
              value={notes}
              placeholder="Turn left near the big tree, and leave the package on the blue door, not on the red door."
              onChange={handleNotesChange}
              autoComplete="false"
            />
          </div>

          {/* Lower buttons to go forward, back or cancel on menu 4 */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                className="unSelectedButton"
                onClick={handleMenu4PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>

              <button
                className="unSelectedButton"
                onClick={handleMenu4PressNext}
              >
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </button>
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 5 - Images and submit the address */}
      {menuNumber === 5 && (
        <div name="menu5" className="centralForm">
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>New</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>5/5</span>{" "}
          </h1>

          <div>
            <h5 style={{ marginTop: "70px", marginBottom: "20px" }}>
              You can also add imagens that might help to locate your address.
            </h5>
          </div>

          <div>
            <ImageUpload onFilesChange={handleImagesUpdate} />
          </div>

          {/* Lower buttons to finish, back or cancel on menu 5 */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                disabled={loading}
                className="unSelectedButton"
                onClick={handleMenu5PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>

              <button
                className="unSelectedButton"
                style={{ backgroundColor: "#BBFFB7" }}
                onClick={handleMenu5Create}
              >
                Create &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </button>
            </div>
            {!loading && (
              <div style={{ marginTop: "40px" }}>
                <button className="cancelButton" onClick={onClose}>
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            )}
            {loading && (
              <div style={{ marginTop: "40px" }}>
                <SyncLoader
                  color={"#4463FF"}
                  loading={loading}
                  css={animationCSS}
                  size={15}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAddress;
