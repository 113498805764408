import React from 'react';

const WebPagePersonal = () => {
  return (
    <div style={{ padding: '20px', marginTop: '80px' }}>
      <h1>Personal Page</h1>
      <h2>This is a sub-header</h2>
      <h3>Another header</h3>
      <p>This is a paragraph with some text inside the personal page route.</p>
      <h4>Smaller header</h4>
      <h5>Even smaller header</h5>
      <h6>The smallest header</h6>
      <p>Here is some more text to fill out the content of the personal page.</p>
    </div>
  );
};

export default WebPagePersonal;