import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: '../images/map/marker.png', // Replace with your custom icon path
  iconSize: [100, 100], // Adjust icon size as needed
  iconAnchor: [50, 50], // Point of the icon which will correspond to marker's location
});

const LocationMarker = ({ coordinates, onSelect }) => {
  const [position, setPosition] = useState(coordinates);

  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      onSelect(e.latlng);
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon}></Marker>
  );
};

const OpenStreetViewComponent = ({ initialCoordinates, onUpdateCoordinates }) => {
  const [coordinates, setCoordinates] = useState(initialCoordinates || { lat: 37.03, lng: -7.84 }); // Olhão

  useEffect(() => {
    if (initialCoordinates) {
      setCoordinates(initialCoordinates);
    }
  }, [initialCoordinates]);

  const handleSelect = (latlng) => {
    setCoordinates(latlng);
    onUpdateCoordinates(latlng);
    //console.log(`Selected coordinates: ${latlng.lat}, ${latlng.lng}`);
  };

  return (
    <MapContainer
      center={coordinates}
      zoom={initialCoordinates ? 40 : 13} // Set zoom to 15 if initialCoordinates are provided, otherwise set it to 13
      style={{ height: '600px', width: '100%', position: 'flex' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker coordinates={coordinates} onSelect={handleSelect} />
    </MapContainer>
  );
};

export default OpenStreetViewComponent;
