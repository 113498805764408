import React, { useState } from "react";

// Icons
import { MDBIcon } from "mdb-react-ui-kit";

// Clipboard copy
import { CopyToClipboard } from "react-copy-to-clipboard";

// InlineNotification
import InlineNotification from "../Components/InlineNotification";

const AddressDetails = ({ address }) =>
{
  const [view, setView] = useState("Human"); // 'Human', 'Json', 'Aligned'
  const [notification, setNotification] = useState(null); // For showing the inlinenotification

  const switchView = (selectedView) =>
  {
    setView(selectedView);
  };

  const handleCopyClick = (message) =>
  {
    const key = Date.now();
    setNotification({ message: message, key: key });
  };

  // Function to open Google Maps in a new window
  const openGoogleMaps = () =>
  {
    const url = `https://www.google.com/maps/search/?api=1&query=${address.location_coordinates_latitude},${address.location_coordinates_longitude}`;
    window.open(url, "_blank");
  };

  // Function to open Waze in a new window
  const openWaze = () =>
  {
    const url = `https://www.waze.com/ul?ll=${address.location_coordinates_latitude},${address.location_coordinates_longitude}`;
    window.open(url, "_blank");
  };

  // **************************************
  // Human view
  const HumanView = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        padding: "10px",
      }}
    >
      {/* Notification */}
      {notification && (
        <InlineNotification
          key={notification.key}
          message={notification.message}
          position={notification.position}
        />
      )}

      {/* Contact name */}
      <h4 style={{ marginBottom: "6px" }}>
        {address.location_contact_name}
        <CopyToClipboard
          text={address.location_contact_name}
          onCopy={() => handleCopyClick("Name copied to clipboard")}
        >
          <button
            style={{
              marginLeft: "20px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="copy" size="xs" style={{ color: "yellowgreen" }} />
          </button>
        </CopyToClipboard>
      </h4>
      {/* Phone */}
      <h6>
        <MDBIcon
          icon="phone"
          style={{ marginLeft: "20px", marginRight: "10px" }}
        />{" "}
        +{address.location_phone}
        <CopyToClipboard
          text={`+${address.location_phone}`}
          onCopy={() => handleCopyClick("Phone copied to clipboard")}
        >
          <button
            style={{
              marginLeft: "20px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="copy" style={{ color: "yellowgreen" }} />
          </button>
        </CopyToClipboard>
      </h6>

      {/* Email */}
      <h6>
        <MDBIcon
          icon="envelope"
          style={{ marginLeft: "20px", marginRight: "10px" }}
        />
        {address.location_email}
        <CopyToClipboard
          text={address.location_email}
          onCopy={() => handleCopyClick("Email copied to clipboard")}
        >
          <button
            style={{
              marginLeft: "20px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="copy" style={{ color: "yellowgreen" }} />
          </button>
        </CopyToClipboard>
      </h6>

      {/* Company name */}
      {address.location_company && (
        <div>
          <h3 style={{ marginBottom: "6px", marginTop: "10px" }}>
            {address.location_company}
            <CopyToClipboard
              text={address.location_company}
              onCopy={() => handleCopyClick("Company copied to clipboard")}
            >
              <button
                style={{
                  marginLeft: "20px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <MDBIcon
                  icon="copy"
                  size="2xs"
                  style={{ color: "yellowgreen" }}
                />
              </button>
            </CopyToClipboard>
          </h3>
        </div>
      )}

      {/* Fiscal ID */}
      {address.location_fiscal_id && (
        <div>
          <h6 style={{ marginBottom: "25px" }}>
            <MDBIcon
              fas
              icon="fingerprint"
              style={{ marginLeft: "20px", marginRight: "10px" }}
            />{" "}
            {address.location_fiscal_id}
            <CopyToClipboard
              text={address.location_fiscal_id}
              onCopy={() => handleCopyClick("Fiscal ID copied to clipboard")}
            >
              <button
                style={{
                  marginLeft: "20px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <MDBIcon icon="copy" style={{ color: "yellowgreen" }} />
              </button>
            </CopyToClipboard>
          </h6>
        </div>
      )}

      {/* Address lines */}
      <div>
        {address.location_address_lines.split("\n").map((line, index) => (
          <p style={{ marginBottom: "0px" }} key={index}>
            {line}

            {index === 0 && (
              <CopyToClipboard
                text={address.location_address_lines}
                onCopy={() => handleCopyClick("Address copied to clipboard")}
              >
                <button
                  style={{
                    marginLeft: "20px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <MDBIcon icon="copy" style={{ color: "yellowgreen" }} />
                </button>
              </CopyToClipboard>
            )}
          </p>
        ))}
      </div>

      {/* Postal code, city and region */}
      <p style={{ marginTop: "10px", marginBottom: "0px" }}>
        {address.location_postal_code} - {address.location_city} -{" "}
        {address.location_region}
      </p>
      <p style={{ marginTop: "6px", fontWeight: "bolder" }}>
        {address.country_name}
      </p>

      {/* Notes */}
      <p style={{ marginTop: "10px", fontWeight: "lighter" }}>
        Notes: {address.location_notes}
      </p>

      {/* Open in Google maps or Waze, open new window */}
      <p className="location-buttons">
      <MDBIcon
        fas
        icon="map-marker-alt"
        size="2x"
        className="icon"
      />
      <button className="location-button" onClick={openGoogleMaps}>
        Google Maps
      </button>
      <button className="location-button" onClick={openWaze}>
        Waze
      </button>
      
      {/* Coordinates will be hidden in the screen size is small */}
      <span className="coordinates">
        Lat: {parseFloat(address.location_coordinates_latitude).toFixed(5)}, Lng:{" "}
        {parseFloat(address.location_coordinates_longitude).toFixed(5)}
      </span>
      </p>
    </div>
  );

  // **************************************
  // json view
  const JsonView = <pre>{JSON.stringify(address, null, 2)}</pre>;

  // **************************************
  // Aligned view
  const AlignedView = (
    <table>
      <tbody style={{ fontSize: "14px" }}>
        {Object.entries(address).map(([key, value]) => (
          <tr key={key}>
            <td style={{ textAlign: "right", paddingRight: "8px" }}>{key}</td>
            <td style={{ textAlign: "center" }}>:</td>
            <td style={{ textAlign: "left", paddingLeft: "8px" }}>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <div
        style={{
          backgroundColor: "lightgray",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginLeft: "10px",
              border: "0px solid",
              textAlign: "left",
            }}
          >
            {view}
          </div>

          <div
            style={{ marginLeft: "auto", display: "flex", alignItems: "right" }}
          >
            <button
              style={{
                border: "1px solid",
                marginRight: "10px",
                borderRadius: "4px",
              }}
              onClick={() => switchView("Human")}
            >
              <MDBIcon icon="user" />
            </button>
            <button
              style={{
                border: "1px solid",
                marginRight: "10px",
                borderRadius: "4px",
              }}
              onClick={() => switchView("Json")}
            >
              <MDBIcon fab icon="js" />
            </button>
            <button
              style={{
                border: "1px solid",
                marginRight: "10px",
                borderRadius: "4px",
              }}
              onClick={() => switchView("Aligned")}
            >
              <MDBIcon icon="table" />
            </button>
          </div>
        </div>
      </div>

      {view === "Human" && HumanView}
      {view === "Json" && JsonView}
      {view === "Aligned" && AlignedView}
    </div>
  );
};

export default AddressDetails;
