// CountryRegionSelector component

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { RegionDropdown } from "react-country-region-selector";
import { getName } from "country-list";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const CountryRegionSelector = ({ disabled, selectedCountry, setSelectedCountry, selectedRegion, setSelectedRegion }) => {
  const [options, setOptions] = useState([]);

  // Fetch and log the country data for debugging
  useEffect(() => {
    const countries = require("country-list/data.json");

    const options = countries
      .map(({ code, name }) => {
        if (!code) {
          console.warn(`No code found for country name: ${name}`);
          return null;
        }
        return {
          value: code,
          label: (
            <div style={{ display: "flex", height: "20px", width: "200%" }}>
              <span
                className={`fi fi-${code.toLowerCase()}`}
                style={{ marginRight: "10px", height: "20px" }}
              ></span>
              {name}
            </div>
          ),
          name: name, // Store country name for filtering
        };
      })
      .filter((option) => option !== null);

    setOptions(options);
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
  };

  const handleRegionChange = (selectedRegion) => {
    setSelectedRegion(selectedRegion);
  };

  // Custom filter function to filter based on country names
  const filterOption = (option, inputValue) => {
    return option.data.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  // *************************************************
  // Render

  return (
    <div>
      <div className="inputGroup">
        {/* Input field: country, uses react-select */}
        <div className="inputLabel">Country of the address</div>
        <Select
          isDisabled={disabled}
          options={options}
          onChange={handleCountryChange}
          value={
            selectedCountry
              ? {
                  value: selectedCountry,
                  label: (
                    <div>
                      <span
                        className={`fi fi-${selectedCountry.toLowerCase()}`}
                        style={{ marginRight: "10px" }}
                      ></span>
                      {getName(selectedCountry)} {/* Display country name, not the country code */}
                    </div>
                  ),
                }
              : null
          }
          formatOptionLabel={({ label }) => label}
          filterOption={filterOption}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "darkblue" : "black",
              borderRadius: "8px",
              backgroundColor: disabled ? "#f5e1cb" : "#E4E9ED",
              display: "flex",
              flexDirection: "row",
              height: "36px",
              textAlign: "left", // Center selected items on the left
            }),
            container: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
        />
      </div>

      {selectedCountry && (
        <div className="inputGroup">
          {/* Input field: region, uses react-country-region-selector regions*/}
          <div className="inputLabel">Region</div>
          <RegionDropdown
           disabled={disabled}
            country={selectedCountry}
            countryValueType="short" // Set the country value type to "short"
            value={selectedRegion}
            onChange={handleRegionChange}
            style={{
              width: "100%", // Make the dropdown fill all horizontal space
              display: "flex",
              flexDirection: "row",
              alignItems: "center", // Center items vertically
              height: "36px",
              borderColor: "black",
              borderRadius: "8px",
              border: "1px",
              borderStyle: "solid",
              backgroundColor: disabled ? "#f5e1cb" : "#E4E9ED",
              options: {
                  backgroundColor: "red",
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CountryRegionSelector;
