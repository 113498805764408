import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// API
import { APIwithoutAuth } from "../AxiosAPI/AxiosAPI";

const ImageDetails = ({ images_ids }) => {
  const [images, setImages] = useState([]);

  console.log ("Image_ids: ", images_ids);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const idsArray = images_ids.split(',').map(id => id.trim());
        const imagePromises = idsArray.map(async (image_id) => {
          const response = await APIwithoutAuth.get(`/image/${image_id}`);
          return response.data.image;  // Extract the base64 image data
        });

        const fetchedImages = await Promise.all(imagePromises);
        console.log("Fetched images array:", fetchedImages);
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    if (images_ids.length > 0) {
      fetchImages();
    }
  }, [images_ids]);


  // Settings for the react slider
  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div>
      {images.length > 0 ? (
        <Slider {...settings}>
          {images.map((imageObj, index) => (
            <div key={index}>
              <img src={imageObj.image} alt={`${index}`} style={{ width: '100%' }} />
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading images...</p>
      )}
    </div>
  );
};

ImageDetails.propTypes = {
  images_ids: PropTypes.string.isRequired,
};

export default ImageDetails;