import axios from "axios";

const getUserCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

const getCountryFromCoordinates = async (latitude, longitude) => {
  const response = await fetch(
    `http://api.geonames.org/countryCodeJSON?lat=${latitude}&lng=${longitude}&username=fastaddress`
  );

  if (!response.ok) {
    throw new Error("Failed to fetch country information.");
  }

  const data = await response.json();
  return data.countryCode;
};

const getUserCountry = async () => {
  try {
    const response = await axios.get("https://ipinfo.io?token=db6b0fea41a70e");
    const country = response.data.country;
    return country;
  } catch (error) {
    console.error("Error fetching user location from ipinfo.io:", error);

    try {
      const { latitude, longitude } = await getUserCoordinates();
      const countryCode = await getCountryFromCoordinates(latitude, longitude);
      return countryCode;
    } catch {
      return null;
    }
  }
};

export default getUserCountry;
