import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert, InputGroup } from "react-bootstrap";

// Animations
import { css } from "@emotion/react";
import { SyncLoader } from "react-spinners";

// Phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import isValidPhoneNumber from 'libphonenumber-js';
import { phoneCleaner } from "../utils/phone";

// Password
import { FaEye, FaEyeSlash } from "react-icons/fa";

// API
import { APIwithoutAuth } from "../AxiosAPI/AxiosAPI";

export default function LoginForm({
  isVisible,
  onClose,
  onSignupButtonClick,
  userCountry,
}) {
  // ********************************************************
  useEffect(() => {
    setError(null);
    setFormData("","");
    setLoading(false);
  }, [isVisible]); // Run this effect when isVisible changes

  // ***************************************
  // Form data values array
  // ***************************************
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(null); // Clears errors if user is adding content
  };

  // **********************************************
  // Handles the phone number input and sets on the
  // formData. This is separated from
  // 'const handleInputChange' as the sender format
  // is different
  // **********************************************
  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: formattedValue,
    }));
    setIsPhoneValid(formattedValue.valid);
    
    if (value && isValidPhoneNumber(formattedValue)) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  // **********************************************
  // Validations before sending the data to the API
  // **********************************************
  const validateForm = (formData) => {
    const errors = {};

    if (!formData.phone) {
      errors.phone = "Phone is required";
    }
      else
        if (formData.phone.length < 5) {
          errors.phone = "Phone number must have more than 5 digits";
      }
        else
        if (!isPhoneValid)
        {
          errors.phone = "Phone number is not valid";
        }

    if (!formData.password) {
      errors.password = "Password is required";
    }
      else
        if (formData.password.length < 5) {
          errors.password = "Password must have more than 5 characters";
        }

    return errors;
  };

  // ***********************************
  // For animation
  const [loading, setLoading] = useState(false);
  const animationCSS = css`
    display: block;
    margin: auto;
  `;

  // ***********************************
  // When pressing the submit button
  // ***********************************
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log ("Submitted data:", formData);
    //console.log('Login:', Login);

    const errors = validateForm(formData);

    if (Object.keys(errors).length === 0) {
      // Form is valid, proceed with API call
      formData.phone = phoneCleaner(formData.phone);

      try {
        // Make an API call to validate the form data
        const response = await sendToAPIFormData(formData);

        console.log("Response from API call: ", response);

        if (response.status) {
          // Close the modal if the form data is valid
          onClose(response, formData.phone);
        } else {
          // Display an error message if the form data is not valid
          if (response.message != null) {
            setError(response.message);
          } else setError("Invalid credentials. Please try again.");
        }
      } catch (error) {
        if (error.isAxiosError) {
          // This block will be executed for errors of type AxiosError
          setError(`AxiosError: ${error.message}`);
          console.log("AxiosError:", error.message);
        } else {
          console.error("API request error:", error);
          // Handle API error (e.g., show a generic error message)
          setError("An error occurred. Please try again later (#123)");
        }
      }
    } else {
      // Form is invalid, handle errors
      setError(Object.values(errors)[0]); // Shows the first error. Others might be present.
    }
  };

  // ********************************************
  // Send API request
  const sendToAPIFormData = async (formData) => {
    let response;
    try {
      // Set loading to true to indicate that data is being fetched
      setLoading(true);
      //formData = {phone: "test@fastseal.eu", password: "1234A"};
      console.log("Submitted data (validateFormData):", formData);

      response = await APIwithoutAuth.post("/api/login", formData);
      //const response = await APIwithoutAuth.get("/version");
      //console.log("Response version: ", response);
      setLoading(false);

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }
    } catch (error) {
      console.error("Error trying to login:", error);
      setLoading(false);      
    } finally {
      // Set loading to false when the operation is complete (either success or error)
      return response.data;
    }
  };

  //
  //
  //
  //
  //
  //
  //

  return (
    <Modal
      className="loginFormPosition"
      show={isVisible}
      onHide={onClose}
      backdrop="static"
      keyboard="False"
    >
      <Modal.Header
        className="px-4 btn-close-white loginmodalHeader"
        closeButton
      >
        <Modal.Title className="ms-auto fw-bold text-uppercase loginmodalTitle">
          Login
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="loginFormColorcss">
        <div className="mb-3 mt-md-1">
          <p className="ms-auto text-center logingrayTextBold">
            Please enter your phone number and password
          </p>
          <div className="mb-3">
            <Form>
              <Form.Group
                className="mb-3 loginformInputMargin"
                controlId="formBasicPhone"
              >
                <Form.Label className="small">Phone number</Form.Label>

                <div>
                  <PhoneInput
                    className="signupPhoneInput"
                    country={userCountry}
                    name="phone"
                    value={formData.PhoneNumber}
                    onChange={handlePhoneInputChange}
                    countryCodeEditable={false}
                    inputProps={{
                      required: true,
                      autoComplete: "phone",
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group
                className="mb-3 loginformInputMargin"
                controlId="formBasicPassword"
              >
                <Form.Label className="small">Password</Form.Label>
                <InputGroup>
                <Form.Control
                  className="loginformInputControl"
                  type={showPassword ? "text" : "password"} // show or hide password based on the showPassword state
                  placeholder="ex: 12#Fsd"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  autoComplete="current-password"
                  onKeyDown={(e) => {
                      if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                />
                <Button
                      variant="secondary"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="loginformBasicCheckbox">
                <p className="small text-center">
                  <a className="grayTextBold" href="#!">
                    Forgot password?
                  </a>
                </p>
              </Form.Group>
              <div>
                <div className="d-grid m-5">
                  {!loading && (
                    <Button variant="primary" onClick={handleSubmit}>
                      Login
                    </Button>
                  )}

                  {/* Make animation and hide the login button */}
                  {loading && (
                    <div className="text-center">
                    <SyncLoader
                      color={"#F263FF"}
                      loading={loading}
                      css={animationCSS}
                      size={11}
                    />
                    </div>
                  )}

                  <Form.Label className="small text-center text-secondary">
                    By pressing login you accept the use of all cookies
                  </Form.Label>
                  <Form.Label className="small text-center text-secondary">
                    Dont'y worry, we only use what is needed to make this work
                  </Form.Label>
                </div>
              </div>
            </Form>

            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}

            <div className="mt-3">
              <p className="mb-0 text-center">
                Don't have an account?{" "}
                <button
                  className="btn fw-bold logingrayTextBold"
                  onClick={() => onSignupButtonClick()}
                  type="button"
                >
                  Sign Up
                </button>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
