import React from "react";
import { MDBIcon } from "mdb-react-ui-kit";

const ConfirmAddressDeleteModal = ({ show, address, onCancel, onConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true" tabIndex="-1">
      <div className="modal-content">
        <h3><strong>Confirm Delete</strong></h3>
        <p>Are you sure you want to delete the address:</p>
        <p><strong>{address.address_designation}</strong> </p>
        <div className="modal-actions">
          <button onClick={onCancel} className="cancel-button">Cancel</button>
          <button onClick={() => onConfirm(address.id)} className="confirm-button">
            <MDBIcon icon="trash" /> Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddressDeleteModal;