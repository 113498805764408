// React
import React, { useState } from "react";
import { Tooltip } from 'react-tooltip';

// Icons
import { MDBIcon } from "mdb-react-ui-kit";

// Address delete confirmation
import ConfirmAddressDeleteModal from "./ConfirmAddressDeleteModal";

// **************************************************
// Button list dynamic made based on the address list
const AddressButtonList = ({
  addresses,
  userPhone,
  onAddAddress,
  onDeleteAddress,
  onEditAddress,
}) =>
{
  const maxAddresses = 4;
  const canAddMoreAddresses = addresses.length < maxAddresses;

  const [showAddressDeleteModal, setShowAddressDeleteModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);

  const handleDeleteAddressClick = (address) =>
  {
    setAddressToDelete(address);
    setShowAddressDeleteModal(true);
  };

  const handleCancelAddressDelete = () =>
  {
    setShowAddressDeleteModal(false);
    setAddressToDelete(null);
  };

  const handleConfirmAddressDelete = (addressId) =>
  {
    onDeleteAddress(addressId);
    setShowAddressDeleteModal(false);
    setAddressToDelete(null);
  };

  const copyHyperlinkToClipboard = (address) =>
  {
    const html = `<a href="https://fastaddress.net/${userPhone}/${address.address_hashcode}" title="Click to obtain address information">${address.address_designation}</a>`;
    const blob = new Blob([html], { type: 'text/html' });
    const data = [new ClipboardItem({ 'text/html': blob })];

    navigator.clipboard.write(data).then(
      function ()
      {
        console.log("Copied to clipboard successfully!");
      },
      function (err)
      {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const copyURLToClipboard = (address) =>
  {
    const html = `<a href="https://fastaddress.net/${userPhone}/${address.address_hashcode}" title="Click to obtain address information">${address.address_designation}</a>`;
    //const html = `https://fastaddress.net/${userPhone}/${address.address_hashcode}`;

    navigator.clipboard.writeText(html).then(
      function ()
      {
        console.log("Copied to clipboard successfully!");
      },
      function (err)
      {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // ********************************************
  // Render

  //console.log("Addresses: ", addresses);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {addresses.map((address, index) => (
        <div key={index} style={{ position: "relative", width: "100%" }}>
          <button
            className="userMainPageButtonAddress"
            key={index}
            onClick={() => onEditAddress(address)}
          >
            <div style={{ fontWeight: "bolder" }}>
              {address.address_designation}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>{address.location_contact_name}</div>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "5px",
                  color: "forestgreen",
                }}
              >
                <MDBIcon icon="phone" />{" "}
              </div>
              <div>{address.location_phone}</div>
            </div>

            <div>{address.location_address_lines}</div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${address.country_acronym}.svg`}
                alt={`Flag of ${address.country_acronym}`}
                style={{ width: "24px", height: "auto", marginRight: "10px" }}
              />
              <div>{address.country_acronym}</div>
              <div>-{address.location_postal_code}</div>
              <div>-{address.location_city}</div>
              <div>-{address.location_region}</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center", // Center items vertically
              }}
            >
              <img
                src="/images/icons/FastAddress.ico"
                alt="fastaddresssmalllogo"
                style={{ height: "16px", width: "auto", marginRight: "10px" }}
              ></img>
              <div>
                +{userPhone} | {address.address_hashcode}
              </div>
            </div>
          </button>

          {/* Functions buttons */}
          <button
            onClick={() => onEditAddress(address)}
            style={{
              position: "absolute",
              top: "16px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MDBIcon
              icon="edit"
              style={{ color: "darkblue", fontSize: "20px" }}
            />
          </button>

          {/* Delete button */}
          <button
            onClick={() => handleDeleteAddressClick(address)}
            style={{
              position: "absolute",
              bottom: "36px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="trash" style={{ color: "red", fontSize: "20px" }} />
          </button>


          {/* Copy URL only to clipboard */}
          <button
            onClick={() => copyURLToClipboard(address)}
            style={{
              position: "absolute",
              bottom: "36px",
              right: "105px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            data-tooltip-id="copyURLTooltip"
            data-tooltip-content="URL will be copied to clipboard"
          >
            <MDBIcon icon="copy" style={{ color: "yellowgreen", fontSize: "20px" }} />
          </button>
          <Tooltip id="copyURLTooltip" place="top" effect="solid" />



          {/* Copy hyperlink to clipboard */}
          <button
            onClick={() => copyHyperlinkToClipboard(address)}
            style={{
              position: "absolute",
              bottom: "36px",
              right: "70px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            data-tooltip-id="copyHyperlinkTooltip"
            data-tooltip-content="Hyperlink will be copied to clipboard"
          >
            <MDBIcon icon="copy" style={{ color: "blue", fontSize: "20px" }} />
          </button>
          <Tooltip id="copyHyperlinkTooltip" place="top" effect="solid" />



        </div>
      ))}
      {/* Add button */}
      <button
        className="userMainPageButtonAddAddress"
        onClick={onAddAddress}
        disabled={!canAddMoreAddresses}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center", // Ensure text is centered
        }}
      >
        {canAddMoreAddresses ? (
          <div>
            <img
              className="userMainPageResponsiveImageHeight"
              src="/images/30x30/plus_30x30.png"
              alt="addAddress"
            ></img>
            <div>Add address</div>
          </div>
        ) : (
          "Not possible to add more than 4 addresses"
        )}
      </button>
      {/* Confirmation Modal */}
      <ConfirmAddressDeleteModal
        show={showAddressDeleteModal}
        address={addressToDelete}
        onCancel={handleCancelAddressDelete}
        onConfirm={handleConfirmAddressDelete}
      />
    </div>
  );
};

export default AddressButtonList;
