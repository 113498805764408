// App.js
import React, { useEffect, useState } from "react";

// CSS import, including custom.css that is compiled from the several scss files
import "./css/custom.css";

// Router to handle the URL (direct addresses)
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from "react-router-dom";

// Components
import HomePage from "./Navigation/HomePage.js";
import Navbar from "./Navigation/Navbar.js";
import Footer from "./Navigation/Footer.js";
import LoginForm from "./Forms/LoginForm";
import SignupForm from "./Forms/SignupForm";
import UserMainPage from "./Navigation/User/UserMainPage.js";
import getUserCountry from "./utils/userCountry.js";
import WebPagePersonal from "./Navigation/WebPages/WebPagePersonal.js";
import WebPageBusiness from "./Navigation/WebPages/WebPageBusiness.js";
import WebPageCompany from "./Navigation/WebPages/WebPageCompany.js";

function AppWrapper()
{
  const location = useLocation();
  const [userCountry, setUserCountry] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userID, setUserID] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() =>
  {
    const fetchUserCountry = async () =>
    {
      console.log("Trying to get user country");
      try
      {
        let country = localStorage.getItem("userCountry");
        if (!country)
        {
          console.log("Country NOT present on local store");
          country = await getUserCountry();
          if (country)
          {
            localStorage.setItem("userCountry", country.toLowerCase());
            setUserCountry(country.toLowerCase());
          } else setUserCountry("us");
        } else
        {
          console.log("Country obtained from local store:", country);
          setUserCountry(country);
        }
      } catch (error)
      {
        console.error("Error fetching user location (probably adblocker):", error);
        setUserCountry("us");
      }
    };

    const storedUserName = localStorage.getItem("userName");
    const storedUserID = localStorage.getItem("userID");
    const storedUserEmail = localStorage.getItem("userEmail");
    const storedUserPhone = localStorage.getItem("userPhone");
    const storedToken = localStorage.getItem("token");

    if (storedUserName && storedUserID && storedToken)
    {
      setUserName(storedUserName);
      setUserID(storedUserID);
      setUserEmail(storedUserEmail);
      setUserPhone(storedUserPhone);
      setToken(storedToken);
    }

    if (location.pathname === "/")
    {
      fetchUserCountry();
    }
  }, [location.pathname]);

  return (
    <App
      userCountry={userCountry}
      userName={userName}
      userID={userID}
      userEmail={userEmail}
      userPhone={userPhone}
      token={token}
      setUserName={setUserName}
      setUserID={setUserID}
      setUserEmail={setUserEmail}
      setUserPhone={setUserPhone}
      setToken={setToken}
    />
  );
}


function App({ userCountry, userName, userID, userEmail, userPhone, token, setUserName, setUserID, setUserEmail, setUserPhone, setToken })
{
  const [isLoginFormVisible, setLoginFormVisible] = useState(false);
  const [isSignupFormVisible, setSignupFormVisible] = useState(false);
  const [isUnAuthorizedMessageVisible, setUnAuthorizedMessageVisible] = useState(false);

  const handleLoginButtonClick = () =>
  {
    setLoginFormVisible(true);
  };

  const handleCloseLoginForm = async (response, formDataPhone) =>
  {
    setLoginFormVisible(false);
    if (response != null && response.status)
    {
      setUserID(response.data.user_id);
      setUserPhone(formDataPhone);
      setUserName(response.data.user_name);
      setUserEmail(response.data.user_email);
      setToken(response.data.token);
      localStorage.setItem("userName", response.data.user_name);
      localStorage.setItem("userID", response.data.user_id);
      localStorage.setItem("userEmail", response.data.user_email);
      localStorage.setItem("userPhone", formDataPhone);
      localStorage.setItem("token", response.data.token);
    }
  };

  const handleSignupButtonClick = () =>
  {
    setSignupFormVisible(true);
    setLoginFormVisible(false);
  };

  const handleCloseSignupForm = () =>
  {
    setSignupFormVisible(false);
  };

  const handleLogoutSuccessfully = () =>
  {
    setUserID(null);
    setUserEmail(null);
    setUserPhone(null);
    setToken(null);
    setUserName(null);
    localStorage.removeItem("userID");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
  };

  const handleUnAuthorized = () =>
  {
    handleLogoutSuccessfully();
    setUnAuthorizedMessageVisible(true);
    setTimeout(() =>
    {
      setUnAuthorizedMessageVisible(false);
    }, 3000);
  };

  return (
    <div className="App">
      <Navbar
        onLoginButtonClick={handleLoginButtonClick}
        onSignupButtonClick={handleSignupButtonClick}
        userName={userName}
        onLogoutSuccessfully={handleLogoutSuccessfully}
        token={token}
      />
      <Routes>
        <Route
          path="/"
          element={
            userName == null ? (
              <HomePage userCountry={userCountry} initialPhoneValue={""} initialHashValue={""} />
            ) : (
              <UserMainPage
                userID={userID}
                userName={userName}
                userPhone={userPhone}
                userEmail={userEmail}
                token={token}
                onUnAuthorized={handleUnAuthorized}
              />
            )
          }
        />
        <Route
          path="/:phone/:hash"
          element={
            userName == null ? (
              <HomePageWithParams userCountry={userCountry} />
            ) : (
              <UserMainPage
                userID={userID}
                userName={userName}
                userPhone={userPhone}
                userEmail={userEmail}
                token={token}
                onUnAuthorized={handleUnAuthorized}
              />
            )
          }
        />
        <Route path="/personal" element={<WebPagePersonal />} />
        <Route path="/business" element={<WebPageBusiness />} />
        <Route path="/company" element={<WebPageCompany />} />
      </Routes>
      {isUnAuthorizedMessageVisible && (
        <div className="logout-message">You have been logged out.</div>
      )}
      <Footer />
      <LoginForm
        isVisible={isLoginFormVisible}
        onClose={handleCloseLoginForm}
        onSignupButtonClick={handleSignupButtonClick}
        userCountry={userCountry}
      />
      <SignupForm
        isVisible={isSignupFormVisible}
        onClose={handleCloseSignupForm}
        userCountry={userCountry}
      />
    </div>
  );
}


const HomePageWithParams = ({ userCountry }) =>
{
  const { phone, hash } = useParams();
  return (
    <HomePage userCountry={userCountry} initialPhoneValue={phone} initialHashValue={hash} />
  );
};

export default function AppContainer()
{
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}