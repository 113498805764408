import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

const Footer = () => {
  return (
    <MDBFooter bgColor="light" className={`footercss`}>
      <section className="d-flex justify-content-center p-2 border-bottom">
        <div>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="google" />
          </a>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href="/#" className="me-4 text-reset">
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-3">
          <MDBRow className="mt-1 mb-0">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="fw-bold mb-4">
                <img
                  src="/images/icons/FastAddress.ico"
                  alt="fastaddresssmalllogo"
                  className="m-1"
                ></img>
                FastAddress
              </h6>
              <p>Fast, consistent and pratical address manager</p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="fw-bold mb-4">Products</h6>
              <p className="mb-1">
                <a href="#!" className="text-reset">
                  Angular
                </a>
              </p>
              <p className="mb-1">
                <a href="#!" className="text-reset">
                  React
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="fw-bold mb-4">Useful links</h6>
              <p className="mb-1">
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p className="mb-1">
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-0">
              <h6 className="fw-bold mb-2">Contact</h6>
              <p className="mb-1">
                <MDBIcon icon="home" className="me-3" />
                +351 910 314 700 | all
              </p>
              <p className="mb-1">
                <MDBIcon icon="envelope" className="me-4" />
                info@fastaddress.net
              </p>
              <p className="mb-1">
                <MDBIcon icon="phone" className="me-3"/> +351 910 314 700
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-2"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        ©2024 FastSeal Unipessoal, Lda
      </div>
    </MDBFooter>
  );
};

export default Footer;
