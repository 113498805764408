// Set up of API interaction is set here. To use include
// import AxiosAPI from '../AxiosAPI/AxiosAPI';

import axios from "axios";

//Local running API
//const baseURL = "http://localhost:8090";

//Remote shared Hostinger
//const baseURL = "https://fastaddress.fastseal.eu/api";

//Remote VPS Hostinger
const baseURL = "https://api.fastaddress.net";

// *********************************
// Connections without extra headers
export const APIwithoutAuth = axios.create({
  baseURL: baseURL,
  timeout: 8000,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

// **********************************
// Connections with cookies and token
export const APIwithAuth = (token) => {
  return axios.create({
    baseURL: baseURL,
    timeout: 8000,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
	withCredentials: true,
  });
};
