import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert, InputGroup } from "react-bootstrap";

// Phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phoneCleaner, validatePhoneNumber } from "../utils/phone";

// Password
import { passwordStrength } from "check-password-strength";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// API
import { APIwithoutAuth } from "../AxiosAPI/AxiosAPI";

// Email
var validator = require("email-validator");

export default function SigninForm({ isVisible, onClose, userCountry }) {
  // ***************************************
  // Form data values array
  // ***************************************
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    name: "",
    username: "", // username is the email to maintain the shield standard use
    email: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVerbose, setPasswordVerbose] = useState(
    "Week: password is empty"
  );
  const [nameVerbose, setNameVerbose] = useState("Add your name and surname");
  const [emailVerbose, setEmailVerbose] = useState("Use a valid email address");

  // *********************************************
  // Reset form data when modal visibility changes
  // Makes sure that when the form starts it have
  // no content
  // *********************************************
  useEffect(() => {
    if (!isVisible) {
      setFormData({
        phone: "",
        password: "",
        name: "",
        username: "",
        email: "",
        passwordVerbose: "Week: password is empty",
        nameVerbose: "Add your name and surname",
        emailVerbose: "Use a valid email address",
      });
      setError(null);
      setSuccess(null);
      setShowPassword(false);
    }
  }, [isVisible]);

  // **************************************************
  // Handles common text input and sets on the formData
  // corresponding value
  // **************************************************
  const handleInputChange = (e) => {
    if (e.target != null) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      // Extended information to the user
      if (name === "password") providePasswordStrenghtInformation(value);
      if (name === "name") provideNameInformation(value);
      if (name === "email") provideEmailInformation(value);
    }
  };

  // **********************************************
  // Handles the phone number input and sets on the
  // formData. This is separated from
  // 'const handleInputChange' as the sender format
  // is different
  // **********************************************
  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: formattedValue,
    }));
  };

  // **********************************************
  // Sets the requirments of the password
  // **********************************************
  const customPasswordRequirments = [
    {
      id: 0,
      value: "Too weak",
      minDiversity: 0,
      minLength: 0,
    },
    {
      id: 1,
      value: "Weak",
      minDiversity: 2,
      minLength: 4,
    },
    {
      id: 2,
      value: "Medium",
      minDiversity: 3,
      minLength: 6,
    },
    {
      id: 3,
      value: "Strong",
      minDiversity: 3,
      minLength: 8,
    },
    {
      id: 4,
      value: "Very strong",
      minDiversity: 4,
      minLength: 10,
    },
  ];

  // **********************************************
  // Validates the password strenght
  // **********************************************
  function validatePassword(password) {
    /*
            using check-password-strength
            */
    if (passwordStrength(password, customPasswordRequirments).id > 2) {
      return true;
    } else {
      return false;
    }
  }

  // **********************************************
  // When the user changes the password this is
  // called from the even and sets a message
  // informing how strong is the password
  // **********************************************
  function providePasswordStrenghtInformation(password) {
    let info = "";
    if (passwordStrength(password, customPasswordRequirments).id < 3) {
      info =
        passwordStrength(password, customPasswordRequirments).value +
        ", improve it including: ";
      if (
        !passwordStrength(
          password,
          customPasswordRequirments
        ).contains.includes("lowercase")
      )
        info += "Lowercase";
      else if (
        !passwordStrength(
          password,
          customPasswordRequirments
        ).contains.includes("uppercase")
      )
        info += "Uppercase characters";
      else if (
        !passwordStrength(
          password,
          customPasswordRequirments
        ).contains.includes("number")
      )
        info += "Numbers";
      else if (password.length < 8) info += "Make it longer";
      else if (
        !passwordStrength(
          password,
          customPasswordRequirments
        ).contains.includes("symbol")
      )
        info += "Symbols";
    } else info = passwordStrength(password, customPasswordRequirments).value;

    setPasswordVerbose(info);
  }

  // **********************************************
  // Validates the name
  // **********************************************
  function validateName(name) {
    if (name.length > 2) {
      return true;
    } else {
      return false;
    }
  }

  // **********************************************
  // When the user changes the name, verbose
  // information is shown so that the user knowns
  // why the input can be improved
  // **********************************************
  function provideNameInformation(name) {
    let info = "";
    if (name.length < 3) info = "Name should have more than 2 characters";
    else if (
      name.length < 5 ||
      !name.includes(" ") ||
      (name.includes(" ") && name.endsWith(" "))
    )
      info += "Consider adding your surname";
    else info = "Looks ok";

    setNameVerbose(info);
  }

  // **********************************************
  // When the user changes the email
  // **********************************************
  function provideEmailInformation(email) {
    let info = "";
    if (validator.validate(email)) info = "Looks ok";
    else info = "Please use a valid email";

    setEmailVerbose(info);
  }

  // **********************************************
  // Validations before sending the data to the API
  // as the form already contains strong validations
  // this is used only to add extra functionalitys
  // and to return error messages
  // **********************************************
  const validateForm = (formData) => {
    const errors = {};

    if (!formData.phone) {
      errors.phone = "Phone is required";
    }

    if (formData.phone.length < 5) {
      errors.phone = "Phone number must have more than 5 digits";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    }

    if (formData.password.length < 5) {
      errors.password = "Password must have more than 5 characters";
    }

    return errors;
  };

  // ***********************************
  // When pressing the submit button
  // validates the data and sends the
  // request to the API
  // ***********************************
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log ("Submitted singup data:", formData);

    const errors = validateForm(formData);

    if (Object.keys(errors).length === 0) {
      // Form is valid, proceed with API call
      formData.phone = phoneCleaner(formData.phone);
      formData.username = formData.email;

      try {
        // Make an API call to validate the form data
        console.log("Submitted API singup data:", formData);
        const response = await sendToAPIFormData(formData);

        console.log("Response from API: ", response);

        if (response.status) {
          setSuccess("Welcome, you can now login");
          //onClose(); // Close the modal if the form data is valid
        } else {
          // Display an error message if the form data is not valid
          if (response.message != null) {
            if (
              Object.values(response.message)[0] ===
              "The Username field must contain a unique value."
            )
              setError("Email already in use, please login instead");
            else if (
              Object.values(response.message)[0] ===
              "The Phone Number field must contain a unique value."
            )
              setError("Phone number already in use, please login instead");
            else setError(Object.values(response.message)[0]);
          } else setError("Invalid credentials. Please try again.");
        }
      } catch (error) {
        if (error.isAxiosError) {
          // This block will be executed for errors of type AxiosError
          setError(`AxiosError: ${error.message}`);
          console.log("AxiosError:", error.message);
        } else {
          console.error("API request error:", error);
          // Handle API error (e.g., show a generic error message)
          setError("An error occurred. Please try again later (#123)");
        }
      }
    } else {
      // Form is invalid, handle errors
      setError(Object.values(errors)[0]); // Shows the first error. Others might be present.
    }
  };

  // ********************************************
  // API request
  // ********************************************
  const sendToAPIFormData = async (formData) => {
    const response = await APIwithoutAuth.post("/api/register", formData);
    //console.log("Response: ", response);

    if (response.status !== 200 && response.status !== 201) {
      throw new Error("API Error");
    }

    return response.data;
  };

  //
  //
  //
  //
  //
  //
  //

  return (
    <Modal
      className="signupFormPosition"
      show={isVisible}
      onHide={onClose}
      backdrop="static"
      keyboard="False"
    >
      <Modal.Header className="px-4 btn-close-white modalHeader" closeButton>
        <Modal.Title className="ms-auto fw-bold text-uppercase modalTitle">
          Sign up
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="signupFormColorcss">
        <div className="mb-3 mt-md-1">
          <p className="ms-auto text-center grayplusTextBold">
            Simply fill up the details below. It does not take long
          </p>
          <div className="mb-3">
            <Form>
              {/* ************ */}
              {/* Phone number */}
              <Form.Group
                className="mb-3 formInputMargin"
                controlId="formBasicPhone"
              >
                <Form.Label className="small">Phone number</Form.Label>

                <div>
                  <PhoneInput
                    className="signupPhoneInput"
                    country={userCountry}
                    name="phone"
                    value={formData.PhoneNumber}
                    onChange={handlePhoneInputChange}
                    countryCodeEditable={false}
                    inputProps={{
                      required: true,
                      autoComplete: "phone",
                    }}
                  />
                </div>
              </Form.Group>

              {/* ********************************************* */}
              {/* Password, show only with a valid phone number */}
              {(error || validatePhoneNumber(formData.phone)) && (
                <Form.Group
                  className="mb-3 formInputMargin"
                  controlId="formBasicPassword"
                >
                  <Form.Label className="small">Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      className="formInputControl"
                      type={showPassword ? "text" : "password"} // show or hide password based on the showPassword state
                      placeholder=""
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      autoComplete="false"
                    />
                    <Button
                      variant="secondary"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  <Form.Label className="small fst-italic">
                    {passwordVerbose}
                  </Form.Label>
                </Form.Group>
              )}

              {/* ********************************************* */}
              {/* Name, show only with a valid password */}
              {(error ||
                (validatePhoneNumber(formData.phone) &&
                  validatePassword(formData.password))) && (
                <Form.Group
                  className="mb-3 formInputMargin"
                  controlId="formUsername"
                >
                  <Form.Label className="small">Name and surname</Form.Label>
                  <Form.Control
                    className="formInputControl"
                    type="username" // Not really user name, but the password forms recommend it https://goo.gl/9p2vKq
                    placeholder="Big John"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="current-password"
                  />
                  <Form.Label className="small fst-italic">
                    {nameVerbose}
                  </Form.Label>
                </Form.Group>
              )}

              {/* ********************************************* */}
              {/* Email, show only with a valid name            */}
              {(error ||
                (validatePhoneNumber(formData.phone) &&
                  validatePassword(formData.password) &&
                  validateName(formData.name))) && (
                <Form.Group
                  className="mb-3 formInputMargin"
                  controlId="formEmail"
                >
                  <Form.Label className="small">Email</Form.Label>
                  <Form.Control
                    className="formInputControl"
                    type="email"
                    placeholder=""
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    autoComplete="email"
                  />
                  <Form.Label className="small fst-italic">
                    {emailVerbose}
                  </Form.Label>
                </Form.Group>
              )}

              {!success &&
                (error ||
                  (validatePhoneNumber(formData.phone) &&
                    validatePassword(formData.password) &&
                    validateName(formData.name) &&
                    validator.validate(formData.email))) && (
                  <div className="d-grid m-5">
                    <Button variant="primary" onClick={handleSubmit}>
                      Sign up
                    </Button>
                  </div>
                )}
            </Form>

            {error && !success && (
              <Alert variant="danger" className="mt-3 text-center">
                {error}
              </Alert>
            )}

            {success && (
              <Alert variant="success" className="mt-3 text-center">
                {success}
              </Alert>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
