import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const dropzoneStyle = {
  border: "2px dashed #007bff",
  borderRadius: "5px",
  backgroundColor: "#e6e6e6",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginRight: "8px",
};

const activeStyle = {
  border: "2px solid #007bff",
  backgroundColor: "#e6f7ff",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 5,
};

const thumb = {
  display: "inline-flex",
  flexDirection: "column",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 161,
  height: 161,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const placeholder = {
  display: "block",
  width: "100%",
  height: "100%",
  backgroundColor: "#f0f0f0",
};

const deleteButtonStyle = {
  position: "absolute",
  bottom: 4,
  left: "50%",
  transform: "translateX(-50%)",
  background: "none",
  border: "none",
  cursor: "pointer",
};

function ImageUpload({ initialFiles = [], onFilesChange }) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (initialFiles.length > 0) {
      const populatedFiles = initialFiles.map((file) => ({
        ...file,
        preview: file.preview.image, // Use the base64 image string for preview
      }));
      setFiles(populatedFiles);
    }
  }, [initialFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const remainingSlots = 4 - files.length;
      if (remainingSlots <= 0) return; // No more slots available

      if (acceptedFiles.length > remainingSlots) {
        acceptedFiles = acceptedFiles.slice(0, remainingSlots);
      }

      const updatedFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      setFiles(updatedFiles);
      onFilesChange(updatedFiles);
    },
  });

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    onFilesChange(newFiles);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFiles(items);
    onFilesChange(items);
  };

  useEffect(() => {
    // Clean up on unmount
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const placeholders = Array(4)
    .fill(null)
    .map((_, index) => files[index] || null);

  return (
    <section className="container">
      <div
        {...getRootProps({
          style: isDragActive
            ? { ...dropzoneStyle, ...activeStyle }
            : dropzoneStyle,
        })}
      >
        <input {...getInputProps()} />
        Drag 'n' drop up to 4 files here, or click to select files
      </div>
      <aside style={thumbsContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={thumbsContainer}
              >
                {placeholders.map((file, index) => (
                  <Draggable
                    key={file ? file.name : `placeholder-${index}`}
                    draggableId={file ? file.name : `placeholder-${index}`}
                    index={index}
                    isDragDisabled={!file}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...(file && provided.dragHandleProps)}
                        style={{ ...thumb, ...provided.draggableProps.style }}
                      >
                        {file ? (
                          <>
                            <div style={thumbInner}>
                              <img
                                src={file.preview}
                                style={img}
                                alt={`Thumb ${index}`}
                              />
                            </div>
                            <button
                              onClick={() => removeFile(index)}
                              style={deleteButtonStyle}
                            >
                              <i
                                className="fa fa-trash-alt"
                                style={{ color: "red" }}
                              />
                            </button>
                          </>
                        ) : (
                          <div style={placeholder} />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </aside>
    </section>
  );
}

export default ImageUpload;
