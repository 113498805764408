import { PhoneNumberUtil } from "google-libphonenumber";

// ***************************************************
// 1) Removes the + sign of the phone number if exists
// 2) Removes all spaces from the string
// ***************************************************
export function phoneCleaner (phone) {
    
    let output = phone;
    
    // Removes leading + signal if exists
    if (output.startsWith("+"))
    output = output.substring(1);
    
    // Removes all white space
    output = output.replace(/\s/g,'');
    
    return output;
}

// **********************************************
// Validates the phone number to understand
// if it complies with the standard of each
// country. It must start with '+'
// **********************************************
export function validatePhoneNumber(phoneNumber) {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;

    if (!phoneNumber.startsWith("+"))
        phoneNumber = "+" + phoneNumber;

    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
        valid = false;
    }
    
    if (valid) {
        return true;
    } else {
        return false;
    }
}